import { Button, Text, VStack } from '@chakra-ui/react';

import useTranslation from '@/utils/i18n/useTranslation';
import { FC } from 'react';
import SimpleSpinner from './SimpleSpinner';

export type ListPaginationProps = {
  currentPage: number;
  perPageLimit: number;
  currentItemsLength: number;
  itemsTotalLength: number;
  loading?: boolean;
  handleLoadMore: () => void;
};

const ListPagination: FC<ListPaginationProps> = (props: ListPaginationProps) => {
  const {
    currentPage,
    perPageLimit,
    currentItemsLength,
    itemsTotalLength,
    loading = false,
    handleLoadMore,
  } = props;

  const { t } = useTranslation();

  const totalPages = Math.ceil(itemsTotalLength / perPageLimit);
  const showLoadMoreButton = currentPage < totalPages;

  return (
    <VStack w='full' mt={5} mb={{ base: '60px', md: '0' }}>
      {showLoadMoreButton &&
        (loading ? <SimpleSpinner /> : <Button onClick={handleLoadMore}>{t('show-more')}</Button>)}
      <Text>
        1 - {currentItemsLength} / {itemsTotalLength}
      </Text>
    </VStack>
  );
};

export default ListPagination;
